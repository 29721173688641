import styled from '@emotion/styled';
import { spacing, colors, breakpoints } from '../../utils/styleguide';
import Image from './Image';

const LogoInnerWrapper = styled.div<{ divider: boolean; disablePadding?: boolean }>`
  flex: 0 1 50%;
  padding: ${({ disablePadding }) => (disablePadding ? 0 : `${spacing[3]}px 0`)};
  margin-bottom: ${({ disablePadding }) => (disablePadding ? 0 : `${spacing[5]}px`)};
  text-align: center;
  box-sizing: border-box;
  object-fit: contain;
  border-right: ${({ divider }) => (divider ? `1px solid ${colors.grey03}` : 'none')};

  ${breakpoints.tablet} {
    flex: 0 1 33%;
  }
  ${breakpoints.desktop} {
    flex: 0 1 20%;
  }
`;

const LogoImage = styled(Image)<{ height?: number }>`
  height: ${({ height }) => height || spacing[5]}px;
  width: auto;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(7%) sepia(0%) saturate(542%) hue-rotate(148deg)
    brightness(98%) contrast(101%);
  opacity: 0.7;
  ${breakpoints.tablet} {
    height: ${({ height }) => height || spacing[6]}px;
  }
`;

type LogoProps = {
  link?: string;
  logoUrl?: string;
  fileName?: string;
  divider: boolean;
  alt?: string;
  height?: number;
  disablePadding?: boolean;
};

export default function Logo({
  link,
  logoUrl,
  fileName,
  divider,
  alt,
  height,
  disablePadding,
}: LogoProps) {
  if (!logoUrl) {
    return null;
  }

  if (!link) {
    return (
      <LogoInnerWrapper divider={divider} disablePadding={disablePadding}>
        <LogoImage
          url={logoUrl}
          fileName={fileName}
          alt={alt ?? ''}
          maxWidth={200}
          height={height}
        />
      </LogoInnerWrapper>
    );
  }

  return (
    <LogoInnerWrapper divider={divider} disablePadding={disablePadding}>
      <a href={link}>
        <LogoImage
          url={logoUrl}
          fileName={fileName}
          alt={alt ?? ''}
          maxWidth={200}
          height={height}
        />
      </a>
    </LogoInnerWrapper>
  );
}
